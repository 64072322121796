<template>
  <div>
        <bread-crumb :navigationArray="navigationLink"/>
        <div class="white-container">
            <h5>{{ $store.getters.GET_LANG ? 'Почетный секретарь Общественной палаты РФ' : 'Honorary Secretary' }}</h5>
            <div class="tabs"></div>
              <section class="content">
                  <div class="img_content" :style="`background: url(` + require('../../assets/photos/velihov_bio.jpg') + `) center / cover`"></div>
                  <div class="block_info" v-if="$store.getters.GET_LANG">
                      <h5>Велихов Евгений Павлович</h5>
                      <p class="title-content">Биография</p>
                      <p class="text-content ckEditor-html">Родился 2 февраля 1935 г. в Москве. Окончил Московский государственный университет им. М.В. Ломоносова. Доктор физико-математических наук, профессор, академик Российской академии наук (РАН).</p>
                      <p class="text-content ckEditor-html">С 1961 года работал в Институте атомной энергии им. И.В. Курчатова. С 1989 г. — директор ИАЭ АН СССР, в 1992-2015 гг. — президент Российского научного центра «Курчатовский институт». С 2015 г. — почетный президент Национального исследовательского центра «Курчатовский институт».</p>
                      <p class="text-content ckEditor-html">В 1970-1978 гг. — член ЦК ВЛКСМ, с 1977 г. — председатель совета молодых ученых и специалистов ЦК ВЛКСМ, в 1983-1988 гг. — председатель Комитета советских ученых в защиту мира против ядерной угрозы. В 1986-1989 гг. — кандидат в члены ЦК КПСС, в 1989-1990 гг. — член ЦК КПСС. В 1984-1989 гг. — депутат Совета Национальностей Верховного Совета СССР 11 созыва от РСФСР, в 1989-1991 гг. – народный депутат СССР.</p>
                      <p class="text-content ckEditor-html">В 1978-2017 гг. — вице-президент, член Президиума, академик-секретарь Отделения нанотехнологий и информационных технологий АН СССР/РАН. С 2011 г. — председатель Президиума Общероссийской общественной организации «Российская ассоциация содействия науке» (РАСН).</p>
                      <p class="text-content ckEditor-html">В 2006-2014 гг. — член Общественной палаты Российской Федерации, Секретарь Общественной палаты Российской Федерации четырех составов подряд. С 2014 г. — Почетный секретарь Общественной палаты Российской Федерации. В 2013-2015 гг. исполнял полномочия Президента Международной ассоциации экономических и социальных советов и схожих институтов (МАЭСССИ).</p>
                      <p class="text-content ckEditor-html">Автор ряда изобретений и открытий, более 450 цитируемых научных публикаций. Является членом Международного совета и почетным профессором МФТИ, иностранным членом Американской ассоциации содействия развитию науки (AAAS), Шведской королевской академии инженерных наук, почетным доктором университетов Нотр-Дам, Тафт, Дрексель (США), Лондонского университета (Великобритания), почетным гражданином г. Рино (США), г. Пловдива (Болгария), лауреатом премии «Наука и мир» Всемирной федерации ученых, премии Сцилларда Американского физического общества, Международной премии им. А.П. Карпинского. Инициатор строительства и член Совета Международного проекта создания термоядерного экспериментального реактора типа токамак (ITER), член Консультативного научного совета Фонда развития Центра разработки и коммерциализации новых технологий «Сколково».</p>
                      <p class="text-content ckEditor-html">Герой Социалистического Труда, Герой Труда Российской Федерации, полный кавалер ордена «За заслуги перед Отечеством», лауреат Ленинской и Государственной премий СССР, Государственной премии Российской Федерации, премии им. М.В. Ломоносова МГУ, премии им. М.Д. Миллионщикова АН СССР, премии «Глобальная энергия». Награжден тремя орденами Ленина, орденами Трудового Красного Знамени, Мужества, Дружбы, орденом Мира и Дружбы народов Венгрии, орденом Украины «За заслуги» III степени, орденом Восходящего солнца Японии III степени «Золотые лучи» на шейной ленте, медалью ЮНЕСКО «За вклад в развитие нанонауки и нанотехнологий», золотой медалью ВДНХ.</p>
                      <p class="text-content ckEditor-html">Основатель и председатель Совета Межрегиональной общественной организации «Достижения молодых», член Совета директоров Международной организации «Junior Achievement — Young Enterprise», председатель попечительского совета Международной общественной организации «Ассоциация юных лидеров». Соучредитель Международного общественного фонда «За выживание и развитие человечества», Координационного Американо-Российского общественного совета (КАРОС), Общероссийского общественного детско-молодежного движения авторской песни «Многоголосье». Доверенное лицо Президента Российской Федерации В.В. Путина.</p>
                    <p class="text-content ckEditor-html">В честь Евгения Павловича Велихова назван астероид 3601, орбита которого находится между Марсом и Юпитером.</p>
                    <p class="text-content ckEditor-html">Скончался 5 декабря 2024 года.</p>
                      <router-link :to="linkButton" class="button-link">Перейти в совет ОП РФ</router-link>
                  </div>
                  <div class="block_info" v-else>
                    <h5>Yevgeny Pavlovich Velikhov</h5>
                    <p class="title-content">Biography</p>
                    <p class="text-content ckEditor-html">Yevgeny Velikhov was born on February 2, 1935 in Moscow. He graduated from Lomonosov Moscow State University. He holds the academic degree of Doctor of Science in physics and mathematics; he is Professor, Academician of the Russian Academy of Sciences (RAS).</p>
                    <p class="text-content ckEditor-html">He has worked at the Kurchatov Institute of Atomic Energy since 1961, being Director of the Institute of Atomic Energy under the USSR Academy of Sciences since 1989, and President of the Kurchatov Russian Scientific Center in 1992-2015. Since 2015 Professor Velikhov has become Honorary President of the Kurchatov Institute National Research Center.</p>
                    <p class="text-content ckEditor-html">Back in 1970-1978 Yevgeny Velikhov was a member of the Komsomol Central Committee, chairman of the Council of Young Scientists and Specialists of the Komsomol Central Committee since 1977, chairman of the Committee of Soviet Scientists in Defense of Peace against Nuclear Threat in 1983-1988. In 1986-1989 Professor Velikhov was an alternate member of the CPSU Central Committee, and member of the CPSU Central Committee in 1989-1990. In 1984-1989 he was a member of the Council of Nationalities of the USSR Supreme Soviet of the 11th convocation representing the RSFSR, in 1989-1991 a People's Deputy of the USSR.</p>
                    <p class="text-content ckEditor-html">In 1978-2017 Professor Velikhov was Vice-President, member of the Presidium, Academician-Secretary of the Nanotechnology and Information Technology Department of the USSR Academy of Sciences/RAS. Since 2011, he has been Chairman of the Presidium of the All-Russian Public Organization “Russian Association for the Advancement of Science” (RASN).</p>
                    <p class="text-content ckEditor-html">In 2006-2014 Professor Velikhov was member of the Civic Chamber of the Russian Federation, Secretary of the Civic Chamber of the Russian Federation for four consecutive terms. Since 2014, he has been Honorary Secretary of the Civic Chamber of the Russian Federation. In 2013-2015, he served as President of the International Association of Economic and Social Councils and Similar Institutions (AICESIS).</p>
                    <p class="text-content ckEditor-html">Professor Velikhov is the author of a number of inventions and discoveries, more than 450 cited scientific publications. He is a member of the International Council and Honorary Professor of MIPT, a foreign member of the American Association for the Advancement of Science (AAAS), the Royal Swedish Academy of Engineering Sciences, Honorary Doctor of Notre Dame, Taft, Drexel Universities (USA), University of London (UK), Honorary Citizen of Reno (USA), Plovdiv (Bulgaria), winner of the Science and Peace Prize of the World Federation of Scientists, the Szilard Prize of the American Physical Society, the A.P. Karpinsky International Prize. He is the initiator of construction and member of the Board of the International Project for the creation of the tokamak-type thermonuclear experimental reactor (ITER), member of the Advisory Scientific Council of the Development Fund under the Skolkovo Center for the Development and Commercialization of New Technologies.</p>
                    <p class="text-content ckEditor-html">Professor Velikhov holds the following awards: Hero of Socialist Labor, Hero of Labor of the Russian Federation; he is a full holder of the Order “For Merit to the Fatherland”, winner of the Lenin and State Prizes of the USSR, the State Prize of the Russian Federation, and the Lomonosov Prize, M.D. Millionshchikov Prize of the USSR Academy of Sciences, Global Energy Prize. He was awarded three Orders of Lenin, Order of Labor Red Banner, Order of Courage, Order of Friendship, Order of Peace and Friendship of the Peoples of Hungary, Order of Merit, 3rd Class (Ukraine), Order of the Rising Sun, 3rs Class “Golden Rays” on the neck ribbon (Japan), UNESCO medal “For Contribution to the Development of Nanoscience and Nanotechnology”, gold medal of the All-Union Exhibition of National Economy.</p>
                    <p class="text-content ckEditor-html">Yevgeny Velikhov is the founder and chairman of the Board of the “Junior Achievement” Interregional Public Organization, member of the Board of Directors of the “Junior Achievement – Young Enterprise” International Organization, chairman of the Board of Trustees of the “Association of Young Leaders” International Public Organization, co-founder of the International Public Foundation “For the Survival and Development of Mankind”, the Coordinating American-Russian Public Council, the All-Russian public children's and youth movement of author song “Polyphony”. He is a trusted representative of the President of the Russian Federation Vladimir Putin.</p>
                    <p class="text-content ckEditor-html">Asteroid 3601, orbiting between Mars and Jupiter, is named in honor of Yevgeny Pavlovich Velikhov.</p>
                    <router-link :to="linkButton" class="button-link">Go to the Council of the CC RF</router-link>
                  </div>
              </section>
            </div>
    </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
export default {
  name: 'VelihovEP',
  components: {
    BreadCrumb
  },
  data () {
    return {
      leader: null,
      linkButton: '/structure_list/130'
    }
  },
  computed: {
    navigationLink () {
      return [
        {
          title: this.$store.getters.GET_LANG ? 'Главная' : 'Home',
          path: '/'
        },
        {
          title: this.$store.getters.GET_LANG ? 'Почетный секретарь Общественной палаты РФ' : 'Honorary Secretary'
        }
      ]
    }
  },
  mounted () {
    document.title = this.$store.getters.GET_LANG ? 'Почетный секретарь Общественной палаты РФ' : 'Honorary Secretary'
  }
}
</script>
<style lang="scss" scoped>
.white-container{
    width: 61.44rem;
    margin: 0 5rem;
    padding: 2.5rem 2.94rem;

    h5{
      font-weight: 600;
      font-size: 1.38rem;
      line-height: 1.75rem;
      color: #1F3245;
  }
  .tabs{
      display: flex;
      border-radius: .125rem;
      border-bottom: .06rem solid #D5D6D9;
      margin: 2rem 0;

      p{
          font-size: .75rem;
          line-height: 1.125rem;
          color: #003E78;
          opacity: 0.6;
          margin-right: 1.25rem;
          padding-bottom: 1.375rem;
          cursor: pointer;
          transition: font-size .2s;
      }

      .active {
          font-weight: 600;
          font-size: .813rem;
          line-height: 1.25rem;
          color: #246CB7;
          border-bottom: .125rem solid #246CB7;
          padding-bottom: 1.25rem;
          margin-bottom: -0.07rem;
          margin-top: -0.15rem;
          transition: font-size .2s;
      }
  }
}

section{
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
  }
.block_info{
    display: flex;
    flex-direction: column;
    width: 33.5rem;
}
img{
    width: 12.13rem;
}

.img_content{
    width: 12.145rem;
    height: 15.0625rem;
    margin-right: 1.88rem;
    border-radius: .5rem;
}

.content{
  .ckEditor-html {
    ::v-deep {
      * {
        margin: revert;
        padding: revert;
        list-style-type: revert;
        font-size: revert;
        font-weight: revert;
      }
    }
  }

  h5{
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 1.5rem;
      color: rgba(23, 23, 23, 0.8);
  }
  .title-content{
      margin-top: 1.25rem;
      margin-bottom: .5rem;
      font-weight: 600;
      font-size: .88rem;
      line-height: 1.38rem;
      color: #1F3245;
  }
  a{
      font-size: .88rem;
      line-height: 1.38rem;
      color: #246CB7;
  }
  .text-content{
      font-size: .88rem;
      line-height: 1.38rem;
      color: rgba(23, 23, 23, 0.8);
      margin-bottom: 1.25rem;
  }
  .button-link{
      width: fit-content;
      font-weight: bold;
      font-size: .88rem;
      line-height: 1.25rem;
      color: #FFFFFF;
      background: #246CB7;
      border-radius: .19rem;
      padding: .5rem 2.125rem .44rem 2.125rem;
  }
  .date-content{
    margin-top: .75rem;
    font-size: .87rem;
    line-height: 1.375rem;
    color: #5E686C;
  }
}
@media screen and (max-width: 768px){
  .img_content{
    width: 10.79rem;
    height: 12.59rem;
    margin-right: 1.24rem;
  }

  .white-container{
    width: auto;
    margin: 0 .75rem;
    padding: 1.86rem 1.25rem;
  }
}
@media screen and (max-width: 420px){

  .white-container{
        width: 100%;
        margin: 0;
        padding: 1.88rem 1rem;
    }
    .tabs{
        overflow-x: auto;
        flex-wrap: nowrap;
        margin: 1.38rem -0.76rem 1.38rem 0;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;
        p{
            flex: 0 0 auto;
            padding-bottom: 1.05rem;
            margin-right: 1rem;
        }
        > .active{
            margin-bottom: 0;
            padding-bottom: 1rem;
        }
    }

  section{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .img_content {
    width: 8.8rem;
    height: 10.82rem;
    margin-bottom: 1.4rem;
  }
  .date-content{
    margin-top: .5rem;
  }
  .block_info{
    width: 100%;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .button-link {
        width: 15rem;
    }
}

</style>
